
<template>
  <section id="latest-news" class="panel">
    <ImageGallery
      v-for="(post, postIndex) in sortedPostsByDate"
      :key="post.post_date"
      :images="post.post_images"
      :index="index[postIndex]"
      :disableScroll="true"
      :glideParams="galleryGlideQueryString"
      @close="index[postIndex] = null" />
    <div class="inset-content">
      <div id="posts-wrapper">
        <div id="posts-grid">
          <h1>Latest News</h1>
          <div id="posts" data-tilt class="scrollable-content">
            <div
              class="post-grid span-row"
              v-for="(post, postIndex) in sortedPostsByDate"
              :key="post.post_date">
              <div class="post-title">
                <h2>{{ post.title }}</h2>
              </div>
              <div class="post-date">
                <h2>{{ prettyDate(post.post_date) }}</h2>
              </div>
              <div class="post-body span-row" v-html="post.body"></div>
              <div id="post-image-wrapper" class="span-row">
                <div
                  v-for="(thumb, thumbIndex) in post.post_images"
                  :key="thumbIndex"
                  class="post-image"
                  @click="index[postIndex] = thumbIndex">
                  <img
                    class="post-image-thumb"
                    :src="thumb"
                    :alt="'Thumbnail image for ' + post.title"
                    v-lazy />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'
import { addPaddingToScrollableContent } from '@/assets/scripts/globals'
import { gsap } from 'gsap/all'
import ImageGallery from '@/components/Latest News/ImageGallery'

export default {
  name: 'LatestNews',
  components: {
    ImageGallery,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      posts: [],
      index: [],
      glideQueryString: 'w=75&h=75&fit=crop',
      galleryGlideQueryString: 'w=2160&h=1440',
    }
  },
  created() {
    this.retrievePosts()
  },
  destroyed() {
    window.removeEventListener('resize', addPaddingToScrollableContent)
  },
  mounted() {
    window.addEventListener('resize', addPaddingToScrollableContent)
  },
  updated() {
    this.$nextTick(() => {
      addPaddingToScrollableContent()
    })
  },
  computed: {
    sortedPostsByDate() {
      return this.posts
        .map(({ post_date, body, id, post_images, title, updated_at }) => ({
          post_date,
          body,
          id,
          post_images,
          title,
          updated_at,
        }))
        .sort((a, b) => new Date(b.post_date) - new Date(a.post_date))
    },
  },
  methods: {
    async retrievePosts() {
      const databaseFile = `${process.env.VUE_APP_ROOT_API}latest-news/read.php`
      try {
        const response = await axios.get(databaseFile)

        // Ensure the response is an object and properly formatted
        if (typeof response.data !== 'object' || !response.data.body) {
          throw new Error('Invalid JSON response')
        }

        // Process the posts if the response is valid
        this.posts = response.data.body.map((post) => ({
          ...post,
          post_images: post.post_images ? JSON.parse(post.post_images) : [],
        }))

        await Promise.all(
          this.posts.map((post, postIndex) =>
            post.post_images.map((image, imageIndex) =>
              this.getImageToken(postIndex, imageIndex, image)
            )
          )
        )

        this.index = Array(this.posts.length).fill(null)

        this.$nextTick(() => {
          gsap.fromTo(
            '#posts-grid *',
            { autoAlpha: 0, y: -10 },
            {
              autoAlpha: 1,
              y: 0,
              z: 20,
              stagger: 0.01,
              delay: 0.9,
              onComplete: () => {
                document.getElementById('posts-grid').style.overflowY = 'auto'
              },
            }
          )
        })
      } catch (error) {
        console.error('Error fetching posts:', error)
        this.toast.error(
          `Could not connect to database to retrieve latest posts: ${error.message}. Please check your internet connection and try again.`
        )
      }
    },
    async getImageToken(postIndex, imageIndex, image) {
      const retrieveImageTokenURL = `${process.env.VUE_APP_ROOT_API}admin/get_image_token.php`
      try {
        const response = await axios.post(retrieveImageTokenURL, {
          image,
          queryParams: this.glideQueryString,
        })
        this.posts[postIndex].post_images[imageIndex] = response.data
      } catch (error) {
        console.error(
          `Failed to retrieve image token for image: ${image}`,
          error
        )
      }
    },
    prettyDate(date) {
      return new Date(date).toLocaleDateString('en-UK', {
        year: 'numeric',
        month: 'long',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#latest-news {
  width: 80vw;
  margin: 0 auto;

  @media #{mq-small} {
    width: 80%;
  }
  @media #{mq-medium} {
    width: 40%;
  }
  @media #{mq-large} {
    width: 40%;
  }
}

#posts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  overflow: hidden;

  @media #{$mq-tiny} {
    width: 100%;
  }
  @media #{$mq-tiny-small} {
    width: 100%;
  }
  @media #{$mq-small} {
    width: 90%;
  }
  @media #{$mq-medium} {
    width: 80%;
  }
  @media #{$mq-large} {
    width: 70%;
  }
}

#posts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  width: 85vw;
  overflow-y: hidden !important;
  height: 76vh;
  padding-top: 5vmin;
}

#posts {
  align-self: start;
  overflow-y: scroll;
  height: 100%;
  scroll-behavior: smooth; /* Smooth scroll effect */
}

.post-grid {
  display: grid;
  grid-template-columns: minmax(60%, 1fr) 3fr;
  gap: 0;
  margin-right: 2em;
}

.span-row {
  align-self: start;
}

.post-title,
.post-date {
  justify-self: start;

  @media #{$mq-tiny-small} {
    font-size: 0.8rem;
  }
  @media #{$mq-tiny} {
    font-size: 0.7rem;
  }
  @media #{$mq-small} {
    font-size: 0.6rem;
  }
  @media #{$mq-medium} {
    font-size: 1.2vmin;
  }
  @media #{$mq-large} {
    font-size: 1.5vmin;
  }
  @media #{$mq-xlarge} {
    font-size: 1.6vmin;
  }
}

.post-date {
  justify-self: end;
  text-align: right;
}

.post-body {
  margin-left: 1em;
  color: rgb(172, 172, 172);

  @media #{$mq-tiny-small} {
    font-size: 0.8rem;
  }
  @media #{$mq-tiny} {
    font-size: 0.8rem;
  }
  @media #{$mq-small} {
    font-size: 0.6rem;
  }
  @media #{$mq-medium} {
    font-size: 1.45vmin;
  }
  @media #{$mq-large} {
    font-size: 1.55vmin;
  }
  @media #{$mq-xlarge} {
    font-size: 1.65vmin;
  }
}

#posts-grid h1 {
  margin: 0 0 0.6em;

  @media #{$mq-tiny-small} {
    font-size: 1.8rem;
  }
  @media #{$mq-tiny} {
    font-size: 1.6;
  }
}
</style>
